
.full-page
  margin-top 0
  min-height 100vh
  width 100%
  margin 0
  padding 3% 0

.default-wrap
  width 90%
  max-width 35rem
  margin 0 auto

.footer

  &-article
    background-color grey-darker
    padding-top 2.2rem
    margin-top 4rem
    font-size size-small
    font-family family-sans
    color white
    perpendicular(1deg, false, grey-darker)
    a
      color grey
      border-bottom-color text
      &:hover {border-bottom-color: inherit}

  &-colophon
    margin-top 2.3rem
    padding-bottom .8rem
    a {color: grey; border-bottom: 0}

.see-more
  label
    &:hover
      color white
      text-decoration underline
  .article-list
    margin-top 0
    display: none
  input
    display none
  input:checked ~ label
    display none
  input:checked ~ label ~ .article-list
    display block

.no-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.no-scrollbar::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}

// Hide content visually while keeping it accessible to assistive technologies
//
// See: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
