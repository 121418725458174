
.article
  &-header
    padding 1rem 0 1.5rem

  &-title
    margin-top 1rem !important
    margin-bottom 0 !important
    text-transform uppercase

  &-author
    a
      border 0
      font-weight 800

  &-meta
    font-size size-small
    font-style italic

  &-share
    margin 3rem auto

    a
      border-bottom 0
      padding 0 10px
