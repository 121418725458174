
/** grid
 * Flex grid
 *
*/

makegrid(breakpoint=false)
  if breakpoint
    @media (min-width: breakpoint)
      display flex
      flex-flow row wrap
  else
    display flex
    flex-flow row wrap

.grid

  &:not(.grid--responsive)
    makegrid()

  &--responsive
    makegrid(responsive-breakpoint)

  // Utilidades de grid
  &--expand
    justify-content space-between

  &--vertical-align
    align-items center

  &--horizontal-align
    justify-content center

  &--fix-margin
    margin-left -(column-gap)
    margin-right -(column-gap)
    margin-top -(column-gap)
    &:not(:last-child)
      margin-bottom -(column-gap)

.grid-item
  flex 1
  padding column-gap
  // Automatic width

$flex-item-disable
  flex none
.grid-item
  &--fifth
    @extends $flex-item-disable
    width 20%
  &--quarter
    @extends $flex-item-disable
    width 25%
  &--third
    @extends $flex-item-disable
    width 33.33%
  &--half
    @extends $flex-item-disable
    width 50%
