.content
  &.is-medium
    font-size: size-5
    code
      font-size: size-6
  &.is-large
    font-size: size-4
    code
      font-size: size-5
  &:not(:last-child)
    margin-bottom: 20px
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading
    font-family family-sans
    line-height: 1.125
    font-weight 700
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
    color: text-strong
    margin-bottom: 1.5rem
  h1,
  h2,
  h3
    &:not(:first-child)
      margin-top: 3.5rem

  h1 { font-size: 1.5rem }
  h2 { font-size: 1.4rem }
  h3 { font-size: 1.3rem }
  @media (min-width: 500px)
    h1 { font-size: 2.2rem }
    h2 { font-size: 1.75rem }
    h3 { font-size: 1.5rem }

  h4
    font-size: 1.25rem
  h5
    font-size: 1.125rem
  h6
    font-size: 1rem
  h3
    color grey-dark
  h4
  h5
  h6
    color grey

.content--extend
  p:not(:last-child)
    margin-bottom: 1em
  li + li
    margin-top: 0.25em
  ol
    list-style: decimal outside
    margin: 1em 2em
  ul
    list-style: disc outside
    margin: 1em 2em
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square

  blockquote
    margin-left -1rem
    border-left 5px solid text
    font-style italic
    font-weight bold
    color grey-dark
    padding: 1rem
    &:not(:last-child)
      margin-bottom: 1em

  pre
    margin-bottom 1.5rem

  a
    transition: border-bottom-color speed easing
    border-bottom 2px solid grey-light
    &:hover
      border-bottom-color grey-darker

.text-center
  text-align center
.text-left
  text-align left
.text-right
  text-align right
