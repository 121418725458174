
.home .article-list
  list-style-type none
  margin-left 0
  margin-bottom 0

#articles
  background-color grey-darker
  perpendicular(-1deg, false, grey-darker)
  padding-top calc(1vw + 2rem)
  color grey-dark

  h2
    color grey-light

.article

  &-item
    font-family family-sans
    position relative
    padding-left 50px
    margin 1rem 0

    i
      position absolute
      content attr(data-count)
      left 0
      top calc(50% - 17px)
      font-size 1.4rem
      text-align right
      display block
      width 25px
      font-style normal
      line-height 1.2

    a
      color grey
      border-bottom-color text
      padding-bottom 2px
      &:hover {border-bottom-color: inherit}
